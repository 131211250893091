<div class="w-100 h-100">
    <header>
        <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom box-shadow">
            <div class="container-fluid px-3">
                <a class="navbar-brand" [routerLink]="['/']">
                    <img src="images/logo.png" alt="Depo Prep Logo" class="dprep-logo" />
                </a>
                <button *ngIf="isAuthenticated"
                        class="navbar-toggler d-block"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target=".navbar-collapse"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation"
                        (click)="isNavCollapsed = !isNavCollapsed">
                    <span class="navbar-toggler-icon"></span>
                </button>
            </div>
        </nav>
    </header>

    <div class="dprep-body-container d-flex position-relative">
        <div class="dp-backdrop h-100 position-absolute w-100 dprep-backdrop"
             *ngIf="!isNavCollapsed && isSmallScreen"
             (click)="isNavCollapsed = true">
        </div>

        <div [class.dprep-collapsed]="!isAuthenticated || isNavCollapsed"
             class="border-end d-flex dprep-left-navigation flex-column justify-content-between">
            <ul class="pt-2 list-unstyled w-100 dprep-left-navigation-menu">
                <ng-container *ngFor="let menuItem of menuItems">
                    <li *ngIf="menuItem.hasPermission">
                        <a [routerLink]="menuItem.routerLink" routerLinkActive="dprep-active">
                            <i [class]="menuItem.iconCss"></i>
                            {{menuItem.title}}
                        </a>
                    </li>
                </ng-container>
            </ul>

            <ul class="pt-2 list-unstyled w-100 dprep-left-navigation-menu">
                <li>
                    <a [routerLink]="['help']" routerLinkActive="dprep-active">
                        <i class="fa-regular fa-circle-question"></i>
                        Help Center
                    </a>
                </li>
                <li>
                    <a role="button" (click)="logout()">
                        <i class="fa-solid fa-right-from-bracket"></i>
                        Log Out
                    </a>
                </li>
            </ul>
        </div>

        <main class="overflow-auto"
              [class.dprep-has-no-left-navigation]="!isAuthenticated || isNavCollapsed"
              dp-spinner-parent>
            <div class="p-3">
                <dp-loading-spinner *ngIf="isLoading" message="Logging you in..."></dp-loading-spinner>
                <router-outlet></router-outlet>
            </div>
        </main>
    </div>

    <footer class="d-flex align-items-center dprep-footer border-top pl-3 text-muted">
        <div class="container">
            &copy; 2024 - Deposition Prep
            <a href="https://google.com" target="_blank">Privacy</a>
        </div>
    </footer>
</div>