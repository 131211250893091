import { Injectable } from "@angular/core";
import { UserManager } from "oidc-client-ts";
import { environment } from "../../../environments/environment";
import { UrlResolverService } from "./helpers/url-resolver.service";
import { Person } from "../models/persons/person";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, lastValueFrom, Observable, Subject } from "rxjs";
import { RolePermission } from "../models/persons/users/role-permission";
import { PermissionAction, PermissionType } from "../models/persons/users/permission";
import { RolePermissionService } from "./persons/users/role-permission.service";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private _userManager: UserManager;

    public user?: Person;
    public authState$ = new BehaviorSubject(AuthState.LoggedOut);

    constructor(
        private readonly _urlResolver: UrlResolverService,
        private readonly _http: HttpClient,
        private readonly _rolePermissionService: RolePermissionService
    ) {
        this._userManager = this.createUserManager();
    }

    private createUserManager(tenantId?: number): UserManager {
        return new UserManager({
            authority: environment.authUrl,
            loadUserInfo: true,
            scope: 'admin_app',
            client_id: 'admin-app',
            redirect_uri: window.location.origin + '/auth/login-redirect',
            post_logout_redirect_uri: window.location.origin + '/auth/sign-out',
            response_type: 'code',
            extraTokenParams: {
                'acr_values': tenantId
                    ? `tenantId:${tenantId}`
                    : ''
            }
        });
    }

    public async getAuthState(): Promise<AuthState> {
        return new Promise<AuthState>(async (resolve, reject) => {
            try {
                if (await this.isAuthenticated()) {
                    return resolve(AuthState.LoggedIn);
                }

                if (window.location.href.indexOf('auth/login-redirect') >= 0) {
                    return resolve(AuthState.LoggingIn);
                }

                return resolve(AuthState.LoggedOut);

            } catch (e) {
                return reject(e);
            }
        });
    }

    public async getTenantId(): Promise<number | null> {
        const user = await this._userManager.getUser();

        if (user) {
            const profile = user.profile as any;

            if (profile.tenId) {
                return parseInt(profile.tenId);
            }
        }

        return null;
    }

    public async isAuthenticated(): Promise<boolean> {
        const tenantId = await this.getTenantId();
        return !!tenantId;
    }

    public signIn(tenantId?: number): Promise<void> {
        if (tenantId) {
            this._userManager = this.createUserManager(tenantId);
        }

        this.authState$.next(AuthState.LoggingIn);

        return this._userManager.signinRedirect();
    }

    public signOut(): void {
        this._userManager.signoutRedirect();
        this.authState$.next(AuthState.LoggedOut);
    }

    public async signInCallback(): Promise<void> {
        await this._userManager.signinCallback();
    }

    public navigateToProfile(): void {
        window.open(`${environment.authUrl}/Identity/Account/Manage`, '_blank');
    }

    public async getAccessToken(): Promise<string | undefined> {
        const user = await this._userManager.getUser();
        return user?.access_token;
    }

    public async loadUserData(): Promise<void> {
        const url = this._urlResolver.resolveUrl('api/users/my');
        const observable = this._http.get(url);

        this.user = await lastValueFrom(observable);
    }

    private hasPermission(myPermissions: RolePermission[], action: PermissionAction, type: PermissionType): boolean {
        let matchingPermission = myPermissions.find(p => p.permission!.id == type);

        if (matchingPermission) {
            switch (action) {
                case PermissionAction.CanCreate:
                    return matchingPermission.canCreate!;
                case PermissionAction.CanDelete:
                    return matchingPermission.canDelete!;
                case PermissionAction.CanEdit:
                case PermissionAction.CanViewDetails:
                    return matchingPermission.canEdit!;
                case PermissionAction.CanList:
                    return matchingPermission.canList!;
            }
        }

        return false;
    }

    public async hasAnyPermisison(action: PermissionAction, ...types: PermissionType[]): Promise<boolean> {
        let myPermissions = await this._rolePermissionService.getAllMyPermissions();

        for (let type of types) {
            if (this.hasPermission(myPermissions, action, type)) {
                return true;
            }
        }

        return false;
    }

    public async nextAuthState(): Promise<void> {
        const authState = await this.getAuthState();
        this.authState$.next(authState);
    }
}

export enum AuthState {
    LoggingIn,
    LoggedIn,
    LoggedOut
}