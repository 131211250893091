import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { ModalHelperService } from "./shared/services/helpers/modal-helper.service";
import { HelperService } from "./shared/services/helpers/helper.service";
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class DepoPrepErrorHandler implements ErrorHandler {
    constructor(private readonly _injector: Injector) {

    }

    private getHttpError(error: any): HttpErrorResponse | null {
        if (error instanceof HttpErrorResponse) {
            return error;
        }

        if (error.rejection && error.rejection instanceof HttpErrorResponse) {
            return error.rejection;
        }

        return null;
    }

    private showErrorMessage(modalHelper: ModalHelperService, messages: string[]): void {
        modalHelper.showMessage({
            title: 'Error',
            messages: messages,
            type: 'error'
        });
    }

    private showGenericErrorMessage(modalHelper: ModalHelperService): void {
        this.showErrorMessage(modalHelper, ['There was an unexpected error. Please try again or contact your system administrator if the error persists.']);
    }

    private showValidationErrors(httpError: HttpErrorResponse, modalHelper: ModalHelperService): void {
        const helperService = this._injector.get(HelperService);
        let errors: string[] = [];

        if (helperService.parseValidationErrors(httpError, errors)) {
            this.showErrorMessage(modalHelper, errors);
        } else {
            this.showErrorMessage(modalHelper, ['Validation failed, but we don\'t have a user-frienly message to show to you. Please contact your system administrator.']);
        }
    }

    private navigateToLogin(): void {
        const router = this._injector.get(Router);
        router.navigate(['/']);
    }

    public handleError(error: any): void {
        console.error(error);

        const modalHelper = this._injector.get(ModalHelperService);

        const httpError = this.getHttpError(error);

        if (httpError) {
            switch (httpError.status) {
                case 400:
                    this.showValidationErrors(httpError, modalHelper)
                    break;
                case 401:
                    // Session expired or MFA required in case if we add MFA in future.
                    this.navigateToLogin();
                    break;
                case 403:
                    this.showErrorMessage(modalHelper, ['You do not have permission to acces this page or record. Please contact your system administrator.']);
                    break;
                case 404:
                    this.showErrorMessage(modalHelper, ['The page or record that you requested doesn\'t exist. Please contact your system administrator.']);
                    break;
                default:
                    this.showGenericErrorMessage(modalHelper);
                    break;
            }
        } else {
            this.showGenericErrorMessage(modalHelper);
        }
    }
}