<ul class="nav nav-tabs" *ngIf="showHeaders">
    <li class="nav-item" *ngFor="let tab of tabs">
        <a class="nav-link"
           [class.active]="tab.isActive"
           [attr.aria-current]="tab.isActive ? 'page' : null"
           role="button"
           (click)="selectTab(tab)">
            {{tab.title}}
        </a>
    </li>
</ul>

<div [class.pt-4]="showHeaders">
    <ng-content select="dprep-tab">

    </ng-content>
</div>