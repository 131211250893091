import { Component, Input } from "@angular/core";

@Component({
    selector: 'dprep-tab',
    templateUrl: './tab.component.html'
})
export class TabComponent {
    @Input()
    public title!: string;

    public isActive: boolean = false;
}