import { Component, HostListener, OnInit } from '@angular/core';
import { AuthService, AuthState } from './shared/services/auth.service';
import { PermissionAction, PermissionType } from './shared/models/persons/users/permission';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
  public isAuthenticated: boolean = false;
  public isNavCollapsed: boolean = false;
  public isSmallScreen: boolean = false;
  public isLoading: boolean = false;

  public menuItems: MenuItem[] = [];

  constructor(
    private readonly _authService: AuthService
  ) {

  }

  public async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this.windowResize();

    await this._authService.nextAuthState();

    this._authService.authState$.asObservable().subscribe(async authState => {
      if (authState == AuthState.LoggingIn || authState == AuthState.LoggedIn) {
        this.isLoading = false;

        if (authState == AuthState.LoggedIn) {
          this.isAuthenticated = true;
          this.menuItems = await this.createMenuItems();
        }
      } else {
        this._authService.signIn();
      }
    });
  }

  private async createMenuItems(): Promise<MenuItem[]> {
    return [
      {
        title: 'Cases',
        iconCss: 'fa-solid fa-folder-open',
        routerLink: ['cases'],
        hasPermission: await this._authService.hasAnyPermisison(PermissionAction.CanList, PermissionType.Cases)
      },
      {
        title: 'Clients',
        iconCss: 'fa-solid fa-building',
        routerLink: ['clients'],
        hasPermission: await this._authService.hasAnyPermisison(PermissionAction.CanList, PermissionType.Clients)
      },
      {
        title: 'Settings',
        iconCss: 'fa-solid fa-gear',
        routerLink: ['admin'],
        hasPermission: true
      }
    ];
  }

  public logout(): void {
    this._authService.signOut();
  }

  public editProfile(): void {
    this._authService.navigateToProfile();
  }

  @HostListener('window:resize')
  public windowResize(): void {
    if (window.innerWidth <= 960) {
      this.isNavCollapsed = true;
      this.isSmallScreen = true;
    } else {
      this.isNavCollapsed = false;
      this.isSmallScreen = false;
    }
  }
}

interface MenuItem {
  title: string;
  routerLink: string[];
  iconCss: string;
  hasPermission: boolean
}
