import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UrlResolverService } from "../../helpers/url-resolver.service";
import { Permission } from "../../../models/persons/users/permission";
import { lastValueFrom, Observable, shareReplay } from "rxjs";
import { RolePermission } from "../../../models/persons/users/role-permission";

@Injectable({
    providedIn: 'root'
})
export class RolePermissionService {
    private _myPermissions$?: Observable<RolePermission[]>;

    constructor(
        private readonly _http: HttpClient,
        private readonly _urlResolver: UrlResolverService
    ) {

    }

    public async getAllPermissions(): Promise<Permission[]> {
        const url = this._urlResolver.resolveUrl('api/users/roles/permissions');
        const observable = this._http.get<Permission[]>(url);

        return await lastValueFrom(observable);
    }

    public async getAll(roleId: number): Promise<RolePermission[]> {
        const url = this._urlResolver.resolveUrl(`api/users/roles/${roleId}/permissions`);
        const observable = this._http.get<RolePermission[]>(url);

        return await lastValueFrom(observable);
    }

    public async addOrUpdate(rolePermission: RolePermission): Promise<RolePermission> {
        const url = this._urlResolver.resolveUrl(`api/users/roles/${rolePermission.role?.id}/permissions/add-or-update`);
        const observable = this._http.put<RolePermission>(url, rolePermission);

        return await lastValueFrom(observable);
    }

    public getAllMyPermissions(): Promise<RolePermission[]> {
        if (!this._myPermissions$) {
            this._myPermissions$ = this._http
                .get<RolePermission[]>(`api/users/roles/permissions/my`)
                .pipe(shareReplay(1));
        }

        return lastValueFrom(this._myPermissions$);
    }
}