
export interface Permission {
    id?: PermissionType;
    name?: string;
    category?: string;
    sortOrder?: number;
}

export enum PermissionType {
    Tenants = 1,
    Cases = 2,
    Clients = 3,
    Users = 4,
    Roles = 5
}

export enum PermissionAction {
    None = 0,
    CanList = 1,
    CanCreate = 2,
    CanViewDetails = 4,
    CanEdit = 8,
    CanDelete = 16
}