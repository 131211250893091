import { Component, ContentChildren, Input, QueryList } from "@angular/core";
import { TabComponent } from "./tab.component";

@Component({
    selector: 'dprep-tab-list',
    templateUrl: './tab-list.component.html'
})
export class TabListComponent {
    @Input()
    public showHeaders: boolean = true;

    @ContentChildren(TabComponent)
    public tabs!: QueryList<TabComponent>;

    public ngAfterContentInit() {
        let activeTabs = this.tabs.filter((tab) => tab.isActive);

        if (activeTabs.length === 0) {
            this.selectTab(this.tabs.first);
        }
    }

    public selectTab(tab: TabComponent) {
        for (let tab of this.tabs) {
            tab.isActive = false;
        }

        tab.isActive = true;
    }
}