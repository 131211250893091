<div class="btn-group">
    <div *ngIf="isExpanded"
         class="dp-backdrop position-fixed w-100 h-100 dprep-backdrop"
         (click)="isExpanded = false">
    </div>

    <button type="button"
            class="btn"
            [class.dropdown-toggle]="showCaret"
            aria-haspopup="true"
            [attr.aria-expanded]="isExpanded"
            (click)="toggleExpanded($event)"
            [class.show]="isExpanded"
            [attr.title]="title"
            #dropdownButton>
        <i *ngIf="iconCss" class="{{iconCss}}"></i>
        {{label}}
    </button>

    <div #dropdownContent
         class="dropdown-menu"
         [class.show]="isExpanded"
         (click)="isExpanded = !isExpanded">
        <ng-content *ngIf="isExpanded">

        </ng-content>
    </div>
</div>